import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import {
  Section,
  SectionHeading,
} from '../components/Section';

import './404.scss';

const Page404 = () => (
  <Layout
    className="Page404"
  >
    <Helmet>
      <html lang="en" />
      <title>404 - Not found :(</title>
      <meta
        name="description"
        content=""
      />
      <link rel="canonical" href="https://ca.customwritings.com/404.html" />
    </Helmet>
    <Section className="section-404 white-bg">
      <SectionHeading
        heading="404"
        subHeading="Not found :("
      />
    </Section>

  </Layout>
);

export default Page404;
